import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import Container from '../components/Container';
import Seo from '../components/Seo';
import config from '../config';
import { Media } from '../helpers';
import { typography } from '../styles/typography';

const aboutMeDesc =
  "Hi, I'm Riley! I'm a 26-year-old software developer from Toronto, Canada, with over 7 years of experience in web and mobile development. I've dabbled in reverse engineering, scripting, and QA, but my real passion is working with React and React Native. When I'm not coding, you can find me organizing or competing in Super Smash Bros. Melee tournaments, fishing, or just building cool software projects.";

const Content = styled.div`
  height: 100%;
  width: 100%;

  ${Media.MOBILE`
    height: auto;
  `};

  > div {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Layout = styled.div`
  height: 375px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  ${Media.MOBILE`
    height: auto;
    flex-direction: column;
  `};
`;

const ImageContent = styled.div`
  width: 50%;

  ${Media.MOBILE`
    width: 100%;
    margin-right: 0;
  `};
`;

const TextContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: ${config.sizes.spacing * 2}px 0;
  width: 50%;

  ${Media.MOBILE`
    padding: ${config.sizes.spacing}px 0;
    width: 100%;
  `};

  > h1 {
    ${typography.h3};
  }

  > p {
    ${typography.p};
  }
`;

function About({ data }) {
  return (
    <>
      <Seo title="About" />
      <Content>
        <Container>
          <Layout>
            <ImageContent>
              <StaticImage
                src="../assets/images/me.jpg"
                alt="A picture of Riley Durant"
                height={375}
                quality={100}
              />
            </ImageContent>
            <TextContent>
              <h1>About</h1>
              <p>{aboutMeDesc}</p>
            </TextContent>
          </Layout>
        </Container>
      </Content>
    </>
  );
}

export default About;
